import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/index.css";

import axios from "axios";
import VueAxios from "vue-axios";

export const API_URL = process.env.VUE_APP_API_URL;

const app = createApp(App);

app.use(store);

export const axiosCustom = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

app.use(VueAxios, axiosCustom);

app.use(router);

app.mount("#app");
