import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import { DirectoryListing } from "@/util";

export interface State {
  jwt: string;
  dark: boolean;
  currentListing?: DirectoryListing;
}

export const key: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
  state: {
    jwt: "",
    dark: false,
    currentListing: undefined,
  },
  getters: {
    dark: (state) => state.dark,
    jwt: (state) => state.jwt,
    currentListing: (state) => state.currentListing,
  },
  mutations: {
    toggleDarkMode(state) {
      state.dark = !state.dark;
      localStorage.theme = state.dark ? "dark" : "light";
    },
    setDarkMode(state, bool) {
      state.dark = bool;
      localStorage.theme = state.dark ? "dark" : "light";
    },
    setCurrentListing(state, listing: DirectoryListing) {
      state.currentListing = listing;
    },
    setJwt(state, jwt: string) {
      state.jwt = jwt;
    },
  },
  actions: {},
  modules: {},
});
